import React from "react";

function Error() {
  return (
    <div>
      <h1 className="error">Oops! page not found!</h1>
    </div>
  );
}

export default Error;